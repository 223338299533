<template>
  <div id="user-list" class="car-brand">
    <ChauffeurDriveAddNew
      v-model="isAddNewCarBrandSidebarActive"
      :id="carBrandId"
      :fleetOptions="fleetOptions"
      :locationOptions="allLocationOptions"
      :agentOptions="agentOptions"
      @reset-id="carBrandId = null"
    ></ChauffeurDriveAddNew>

    <!-- list filters -->
    <v-card>
      <v-card-title class="d-flex">
        <div class="mr-auto">Chauffeur Drive</div>
        <div class="d-flex">
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
            clearable
            class="text-search me-3 mb-4"
          >
          </v-text-field>
          <v-btn icon class="mb-4" @click="showFilters = !showFilters">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="24" class="me-1" v-bind="attrs" v-on="on">
                  {{ icons.mdiFilter }}
                </v-icon>
              </template>
              <span>Filters</span>
            </v-tooltip>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dark v-bind="attrs" v-on="on">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon size="24" class="me-1" v-bind="attrs" v-on="on">
                      {{ icons.mdiSort }}
                    </v-icon>
                  </template>
                  <span>Sort By</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in sortOptions" :key="index" @click="sortBy = item.value">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn icon class="mb-4" @click="exportToExcel()">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="24" class="me-1" v-bind="attrs" v-on="on">
                  {{ icons.mdiDownload }}
                </v-icon>
              </template>
              <span>Download</span>
            </v-tooltip>
          </v-btn>
          <!-- <v-btn color="primary" class="mb-4 ml-3" @click.stop="addChauffeurDrive">
            <v-icon size="17" class="me-1">
              {{ icons.mdiPlus }}
            </v-icon>
            <span>Add</span>
          </v-btn> -->
        </div>
      </v-card-title>

      <!-- actions -->
      <v-card-text class="d-flex flex-wrap pb-0" v-if="showFilters">
        <!-- search -->
        <v-select
          v-model="statusFilter"
          placeholder="Select Status"
          :items="statusOptions"
          item-text="title"
          item-value="value"
          outlined
          clearable
          dense
          hide-details
          class="user-search me-3 mb-4"
        ></v-select>

        <v-select
          v-model="typeFilter"
          placeholder="Select Type"
          :items="typeOptions"
          item-text="title"
          item-value="value"
          outlined
          clearable
          dense
          hide-details
          class="user-search me-3 mb-4"
        ></v-select>

        <v-select
          v-model="locationFilter"
          placeholder="Select Location"
          :items="locationOptions"
          item-text="title"
          item-value="value"
          outlined
          clearable
          dense
          hide-details
          class="user-search me-3 mb-4"
        ></v-select>

        <v-select
          v-model="brandFilter"
          placeholder="Select Brand"
          :items="brandOptions"
          item-text="title"
          item-value="value"
          outlined
          clearable
          dense
          hide-details
          class="user-search me-3 mb-4"
        ></v-select>
      </v-card-text>

      <p class="ml-2">{{ filteredChauffeurDrive.length || 'No'  }} records found</p>
      <v-row>
        <v-col cols="12" lg="4" xl="3">
          <AddNewCard title="Add Car" minHeight="175" @click="addChauffeurDrive" />
        </v-col>
        <v-col
          cols="12"
          lg="4"
          xl="3"
          v-for="(
            {
              id,
              carbrand,
              markimgcarmob,
              active,
              markltype,
              updatedon,
              carclass,
              location,
              active1,
              active2,
              active3,
              price1,
              price2,
              price3,
              package_hours1,
              package_hrs2,
              package_kms1,
              package_kms2,
              toll,
              parking_fee,
              soldout,
              clubmember,
              popular,
            },
            c
          ) in paginatedChauffeurDrive[page - 1]"
          :key="c"
        >
          <ChauffeurDriveCard
            :id="id"
            :brandName="carbrand"
            :image="markimgcarmob"
            :isActive="active"
            :type="markltype"
            :updatedOn="formatDate(updatedon.toDate(), 'DD-MMM-YYYY hh:mm A')"
            :fleetClass="carclass"
            :location="location"
            :isFullDayActive="active1"
            :isOutstationActive="active2"
            :isAirportActive="active3"
            :fullDay="{ active: active1, price: price1, hours: package_hours1, kms: package_kms1 }"
            :outstation="{ active: active2, price: price2, hours: package_hrs2, kms: package_kms2 }"
            :airport="{ active: active3, price: price3, toll: toll, parkingFee: parking_fee }"
            :soldout="soldout"
            :clubmember="clubmember"
            :popular="popular"
            @edit-selfdrive="editChauffeurDrive"
          />
        </v-col>
      </v-row>
      <div class="d-flex" v-if="filteredChauffeurDrive.length">
        <div class="mt-2 mr-auto ml-5">
          <span>Items per page: {{ itemsPerPage }} </span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dark v-bind="attrs" v-on="on">
                {{ icons.mdiMenuDown }}
              </v-icon>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in itemsPerPageOptions" :key="index" @click="itemsPerPage = item.title">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="d-flex">
          <span class="mt-2">{{ countText }}</span>
          <v-pagination v-model="page" :length="paginatedChauffeurDrive.length"></v-pagination>
        </div>
        ̉
      </div>
    </v-card>
  </div>
</template>

<script>
import { ref, onUnmounted, computed } from '@vue/composition-api'
import { mdiPlus, mdiChevronLeft, mdiChevronRight, mdiFilter, mdiSort, mdiMenuDown, mdiDownload } from '@mdi/js'
import { reject, isEmpty, sortBy as sort, filter } from 'lodash'
import useChauffeurDrive from './useChauffeurDrive'
import useCarFleet from '../../masters/carfleet/useCarFleet'
import useLocation from '../../masters/location/useLocation'
import useAgent from '../../masters/agent/useAgent'
import useMoment from '../../../composables/useMoment'
import useExcel from '@/composables/useExcel'
import ChauffeurDriveCard from './ChauffeurDriveCard.vue'
import ChauffeurDriveAddNew from './ChauffeurDriveAddNew.vue'
import AddNewCard from '@/components/AddNewCard.vue'

export default {
  components: {
    ChauffeurDriveCard,
    ChauffeurDriveAddNew,
    AddNewCard,
  },
  setup() {
    const isAddNewCarBrandSidebarActive = ref(false)
    const carBrandId = ref(null)
    const showFilters = ref(true)

    const sortOptions = [
      { title: 'Last Updated', value: { by: 'updatedon', order: 'desc' } },
      { title: 'First Updated', value: { by: 'updatedon', order: 'asc' } },
      { title: 'Name : A to Z', value: { by: 'carbrand', order: 'asc' } },
      { title: 'Name : Z to A', value: { by: 'carbrand', order: 'desc' } },
      { title: 'Location : A to Z', value: { by: 'location', order: 'asc' } },
      { title: 'Location : Z to A', value: { by: 'location', order: 'desc' } },
    ]

    const itemsPerPageOptions = [{ title: '15' }, { title: '30' }, { title: '45' }, { title: 'All' }]

    const {
      page,
      itemsPerPage,
      chauffeurDrive,
      filteredChauffeurDrive,
      paginatedChauffeurDrive,
      searchQuery,
      statusFilter,
      statusOptions,
      typeFilter,
      typeOptions,
      locationFilter,
      locationOptions,
      brandFilter,
      brandOptions,
      sortBy,
      loading,

      unsubscribeChauffeurDrive,
    } = useChauffeurDrive()

    const { carFleets, unsubscribeCarFleet } = useCarFleet()
    const { locations, unsubscribeLocations } = useLocation()
    const { agents, unsubscribeAgents } = useAgent()

    const fleetOptions = computed(() => {
      return sort(carFleets.value, ['brand'])
    })

    const allLocationOptions = computed(() => {
      const filteredLocations = filter(locations.value, 'active')
      return sort(filteredLocations, ['description'])
    })

    const agentOptions = computed(() => {
      const filteredAgents = filter(agents.value, 'active')
      return sort(filteredAgents, ['fullname'])
    })

    const countText = computed(() => {
      if (paginatedChauffeurDrive.value.length) {
        const previous = itemsPerPage.value == 'All' ? 0 : (page.value - 1) * itemsPerPage.value
        const start = previous + 1
        const end = previous + paginatedChauffeurDrive.value[page.value - 1].length
        return `${start}-${end} of ${filteredChauffeurDrive.value.length}`
      }
      return ''
    })

    const { formatDate } = useMoment()

    const { exportAsExcelFile } = useExcel()

    const exportToExcel = () => {
      const excelArray = filteredChauffeurDrive.value.map(item => {
        return {
          Car: item.carbrand + ' ' + item.carclass,
          Type: item.markltype,
          Location: item.location,
          Color: item.color,
          'Launch Year': item.launch_year,
          'Is Standard': item.active1,
          'Standard Package': item.package_hours1 + 'Hr - ' + item.package_kms1 + 'Kms',
          'Standard Price': item.price1,
          'Is Outstation': item.active2,
          'Outstation Package': item.package_hrs2 + 'Hr - ' + item.package_kms2 + 'Kms',
          'Outstation Price': item.price2,
          'Is Airport': item.active3,
          Toll: item.toll,
          'Parking Fee': item.parking_fee,
          'Airport Price': item.price3,
        }
      })

      exportAsExcelFile(excelArray, 'Chauffeur_CarFleet')
    }

    const addChauffeurDrive = () => {
      carBrandId.value = null
      isAddNewCarBrandSidebarActive.value = !isAddNewCarBrandSidebarActive.value
    }

    const editChauffeurDrive = id => {
      carBrandId.value = id
      isAddNewCarBrandSidebarActive.value = !isAddNewCarBrandSidebarActive.value
    }

    const getSliderImages = sliderString => {
      if (!sliderString) {
        return []
      }
      const images = sliderString.split('|')
      return reject(images, isEmpty)
    }

    onUnmounted(() => {
      unsubscribeChauffeurDrive()
      unsubscribeCarFleet()
      unsubscribeLocations()
      unsubscribeAgents()
    })

    return {
      page,
      itemsPerPage,
      carBrandId,
      showFilters,
      isAddNewCarBrandSidebarActive,
      statusOptions,
      typeOptions,
      chauffeurDrive,
      filteredChauffeurDrive,
      paginatedChauffeurDrive,
      searchQuery,
      statusFilter,
      typeFilter,
      locationFilter,
      locationOptions,
      brandFilter,
      sortBy,
      sortOptions,
      loading,
      formatDate,
      editChauffeurDrive,
      addChauffeurDrive,
      itemsPerPageOptions,
      countText,
      getSliderImages,
      fleetOptions,
      allLocationOptions,
      brandOptions,
      agentOptions,
      exportToExcel,
      // icons
      icons: {
        mdiPlus,
        mdiChevronLeft,
        mdiChevronRight,
        mdiFilter,
        mdiSort,
        mdiMenuDown,
        mdiDownload,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.car-brand {
  .car-brand-count {
    font-size: 1rem;
  }
  .v-input {
    max-width: 200px;
  }
  .text-search {
    min-width: 300px;
  }
}
</style>
