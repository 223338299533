import useCommon from '@/composables/useCommon'
import { computed, ref, watch } from '@vue/composition-api'
import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore'
import { chunk, filter, isNil, orderBy as sortOrder } from 'lodash'
import { db } from '/src/firebase/config'
import { mdiCurrentAc } from '@mdi/js'

const chauffeurDriveCollection = collection(db, 'cdrive_car')

export default function useUsersList() {
  const { getOptions, getCount, createOptions, stringContainsAny } = useCommon()
  const blankChauffeurDriveData = {
    active: true,
    active1: false,
    active2: false,
    active3: false,
    agent_active: [],
    agent_name: [],
    agent_show: [],
    brandactive: null,
    brandclass: '',
    brandimg: '',
    carbrand: '',
    carclass: '',
    carfleet_id: '',
    carfleetactive: null,
    color: '',
    launch_year: '',
    locactive: null,
    location: '',
    markimgcarmob: '',
    markltype: '',
    package_hours1: '8',
    package_hrs2: '12am-12am',
    package_kms1: 80,
    package_kms2: 250,
    parking_fee: null,
    price1: null,
    price2: null,
    price3: null,
    toll: null,
    avatar_images: [],
    offer: false
  }

  const chauffeurDriveData = ref(JSON.parse(JSON.stringify(blankChauffeurDriveData)))

  const chauffeurDrive = ref([])
  const filteredChauffeurDrive = ref([])
  const paginatedChauffeurDrive = ref([])
  const error = ref(null)
  const page = ref(1)
  const itemsPerPage = ref(30)

  const searchQuery = ref('')
  const statusFilter = ref('Active')
  const typeFilter = ref(null)
  const locationFilter = ref(null)
  const brandFilter = ref(null)
  const sortBy = ref(null)
  const loading = ref(false)
  let unsubscribe = null
  const options = ref({
    sortBy: ['updatedon'],
    sortDesc: [true],
  })

  const statusOptions = computed(() => {
    const mappedValuesWithCount = getCount(chauffeurDrive.value, 'active')
    if (mappedValuesWithCount['true']) mappedValuesWithCount['Active'] = mappedValuesWithCount['true']
    if (mappedValuesWithCount['false']) mappedValuesWithCount['Inctive'] = mappedValuesWithCount['false']
    delete mappedValuesWithCount['true']
    delete mappedValuesWithCount['false']
    return createOptions(mappedValuesWithCount)
  })

  const typeOptions = computed(() => {
    return getOptions(chauffeurDrive.value, 'markltype')
  })

  const locationOptions = computed(() => {
    return getOptions(chauffeurDrive.value, 'location')
  })

  const brandOptions = computed(() => {
    return getOptions(chauffeurDrive.value, 'carbrand')
  })

  const queryOptions = () => {
    const qOptions = []

    qOptions.push(where('agent_active', 'array-contains', true))
    qOptions.push(where('brandactive', '==', true))
    qOptions.push(where('locactive', '==', true))
    qOptions.push(where('carfleetactive', '==', true))

    const orderByKey = options.value.sortBy[0] ? options.value.sortBy[0] : 'updatedon'
    const order = options.value.sortDesc[0] ? 'desc' : 'asc'
    qOptions.push(orderBy(orderByKey, order))

    return qOptions
  }

  // fetch data
  const fetchChauffeurDrive = async () => {
    try {
      if (unsubscribe) {
        unsubscribe()
      }
      loading.value = true
      chauffeurDrive.value = []
      filteredChauffeurDrive.value = []

      const q = query(chauffeurDriveCollection, ...queryOptions())
      unsubscribe = onSnapshot(q, querySnapshot => {
        chauffeurDrive.value = []
        querySnapshot.forEach(doc => {
          chauffeurDrive.value.push({
            id: doc.id,
            ...doc.data(),
          })
        })
        filteredChauffeurDrive.value = chauffeurDrive.value
        
        filterChauffeurDrive()
      })
    } catch (e) {
      console.log(e)
    } finally {
      loading.value = false
    }
  }

  const checkIfChauffeurDriveExist = async () => {
    const q = query(
      chauffeurDriveCollection,
      where('carbrand', '==', chauffeurDriveData.value.carbrand),
      where('markltype', '==', chauffeurDriveData.value.markltype),
      where('carclass', '==', chauffeurDriveData.value.carclass),
    )
    const querySnapshot = await getDocs(q)
    return !!querySnapshot.size
  }

  const checkIfPackageExist = () => {
    const { active1, active2, active3 } = chauffeurDriveData.value
    return active1 || active2 || active3
  }

  const addChauffeurDrive = async () => {

    try {
      loading.value = true
      error.value = null
      if (chauffeurDriveData.value.active) {
        if (!checkIfPackageExist()) {
          throw new Error('Add atleast one package!')
        }
      }
      // if (await checkIfChauffeurDriveExist()) {
      //   throw new Error('Self drive car already exist!')
      // }
      const chauffeurDrive = {
        ...chauffeurDriveData.value,
        createdon: serverTimestamp(),
        updatedon: serverTimestamp(),
      }

      if(chauffeurDrive['avatarImages'] == undefined){
        chauffeurDrive['avatarImages'] = [];
      }
      await addDoc(chauffeurDriveCollection, chauffeurDrive)
    } catch (e) {
      error.value = e.message
      console.log(e)
    } finally {
      loading.value = false
    }
  }

  const editChauffeurDrive = async id => {
    try {
      loading.value = true
      error.value = null
      if (chauffeurDriveData.value.active) {
        if (!checkIfPackageExist()) {
          throw new Error('Add atleast one package!')
        }
      }
      const prevCarBrand = filter(chauffeurDrive.value, carBrand => carBrand.id == id)
      let carBrand = {
        ...chauffeurDriveData.value,
        updatedon: serverTimestamp(),
      }
      // if (
      //   prevCarBrand.length &&
      //   (prevCarBrand[0].description != carBrand.description || prevCarBrand[0].markltype != carBrand.markltype)
      // ) {
      //   if (await checkIfChauffeurDriveExist()) {
      //     throw new Error('Car brand already exist!')
      //   }
      // }
      const docReference = doc(db, 'cdrive_car', id)
      await updateDoc(docReference, carBrand)
    } catch (e) {
      error.value = e.message
      console.log(e)
    } finally {
      loading.value = false
    }
  }

  const filterChauffeurDrive = () => {
    let filteredBrands = chauffeurDrive.value
    if (searchQuery.value && searchQuery.value.length) {
      filteredBrands = filter(filteredBrands, ({ carbrand, carclass, markltype, location }) => {
        return stringContainsAny(carbrand + carclass + markltype + location, searchQuery.value)
      })
    }

    if (statusFilter.value && statusFilter.value.length) {
      const activeValue = statusFilter.value == 'Active'
      filteredBrands = filter(filteredBrands, ({ active }) => {
        return activeValue == active
      })
    }

    if (typeFilter.value && typeFilter.value.length) {
      filteredBrands = filter(filteredBrands, ({ markltype }) => {
        return typeFilter.value == markltype
      })
    }

    if (locationFilter.value && locationFilter.value.length) {
      filteredBrands = filter(filteredBrands, ({ location }) => {
        return locationFilter.value == location
      })
    }

    if (brandFilter.value && brandFilter.value.length) {
      filteredBrands = filter(filteredBrands, ({ carbrand }) => {
        return brandFilter.value == carbrand
      })
    }

    if (!isNil(sortBy.value)) {
      filteredBrands = sortOrder(filteredBrands, [sortBy.value.by], [sortBy.value.order])
    }

    filteredChauffeurDrive.value = filteredBrands
  }

  watch([searchQuery, statusFilter, typeFilter, locationFilter, brandFilter, sortBy], () => {
    filterChauffeurDrive()
  })

  watch([filteredChauffeurDrive, itemsPerPage], () => {
    const chunkSize = itemsPerPage.value == 'All' ? filteredChauffeurDrive.value.length : itemsPerPage.value
    paginatedChauffeurDrive.value = chunk(filteredChauffeurDrive.value, chunkSize)
    page.value = 1
  })

  const unsubscribeChauffeurDrive = () => {
    unsubscribe()
  }

  fetchChauffeurDrive()

  return {
    chauffeurDrive,
    page,
    itemsPerPage,
    filteredChauffeurDrive,
    paginatedChauffeurDrive,
    searchQuery,
    statusFilter,
    statusOptions,
    typeFilter,
    typeOptions,
    locationFilter,
    locationOptions,
    brandFilter,
    brandOptions,
    sortBy,
    loading,
    chauffeurDriveData,
    blankChauffeurDriveData,
    error,

    unsubscribeChauffeurDrive,
    addChauffeurDrive,
    editChauffeurDrive,
  }
}
