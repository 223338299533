import useAppConfig from '@/@core/@app-config/useAppConfig'
import useCommon from '@/composables/useCommon'
import useFirestoreCollectionNames from '@/composables/useFirestoreCollectionNames'
import { ref, watch } from '@vue/composition-api'
import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
  writeBatch,
} from 'firebase/firestore'
import { chunk, filter, indexOf, isNil, orderBy as sortOrder } from 'lodash'
import { db } from '/src/firebase/config'

export default function useAgentList() {
  const { stringContainsAny } = useCommon()
  const { agentsCollectionName, locationCollectionName, selfDriveCarCollectionName, chauffeurDriveCarCollectionName } =
    useFirestoreCollectionNames()
  const { country } = useAppConfig()

  let blankAgentData = {
    fullname: '',
    location_id: '',
    email: '',
    email1: '',
    email2: '',
    phone: '',
    phone1: '',
    phone2: '',
    active: true,
  }

  if (country.value == 'UK') {
    blankAgentData = {
      ...blankAgentData,
      email3: '',
    }
  }

  const agentData = ref(JSON.parse(JSON.stringify(blankAgentData)))

  const agents = ref([])
  const filteredAgents = ref([])
  const paginatedAgents = ref([])
  const error = ref(null)
  const page = ref(1)
  const itemsPerPage = ref(16)

  const searchQuery = ref('')
  const statusFilter = ref(true)
  const locationFilter = ref(null)
  const sortBy = ref(null)
  const loading = ref(false)

  const locationOptions = ref([])
  let unsubscribe = null
  const options = ref({
    sortBy: ['updatedon'],
    sortDesc: [true],
  })

  const queryOptions = () => {
    const qOptions = []

    const orderByKey = options.value.sortBy[0] ? options.value.sortBy[0] : 'updatedon'
    const order = options.value.sortDesc[0] ? 'desc' : 'asc'
    qOptions.push(orderBy(orderByKey, order))

    return qOptions
  }

  // fetch data
  const fetchAgents = async () => {
    try {
      if (unsubscribe) {
        unsubscribe()
      }
      loading.value = true
      agents.value = []
      filteredAgents.value = []

      const q = query(collection(db, agentsCollectionName.value), ...queryOptions())
      unsubscribe = onSnapshot(q, querySnapshot => {
        agents.value = []
        querySnapshot.forEach(doc => {
          agents.value.push({
            id: doc.id,
            ...doc.data(),
          })
        })
        filteredAgents.value = agents.value
        filterAgents()
      })
    } catch (e) {
      console.log(e)
    } finally {
      loading.value = false
    }
  }

  const fetchLocations = async () => {
    const q = query(collection(db, locationCollectionName.value), orderBy('description'))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach(doc => {
      locationOptions.value.push(doc.data().description)
    })
  }

  const checkIfAgentExist = async () => {
    const q = query(collection(db, agentsCollectionName.value), where('fullname', '==', agentData.value.fullname))
    const querySnapshot = await getDocs(q)
    return !!querySnapshot.size
  }

  const addAgent = async () => {
    try {
      error.value = null
      if (await checkIfAgentExist()) {
        throw new Error('Agent already exist!')
      }
      const agent = {
        ...agentData.value,
        createdon: serverTimestamp(),
        updatedon: serverTimestamp(),
      }
      await addDoc(collection(db, agentsCollectionName.value), agent)
    } catch (e) {
      error.value = e.message
      console.log(e)
    }
  }

  const editAgent = async id => {
    try {
      error.value = null
      const prevAgent = filter(agents.value, agent => agent.id == id)
      const agent = {
        ...agentData.value,
        updatedon: serverTimestamp(),
      }
      if (prevAgent.length && prevAgent[0].fullname != agent.fullname) {
        if (await checkIfAgentExist()) {
          throw new Error('Agent already exist!')
        }
      }
      await batchUpdate(id, prevAgent[0], agent)
      // const docReference = doc(db, agentsCollectionName.value, id)
      // await updateDoc(docReference, agent)
    } catch (e) {
      error.value = e.message
      console.log(e)
    }
  }

  const batchUpdate = async (agentId, prevAgent, newAgent) => {
    const { fullname, active } = newAgent
    const agentDocRef = doc(db, agentsCollectionName.value, agentId)

    const selfDriveCollection = selfDriveCarCollectionName.value
    const querySelfDrive = query(
      collection(db, selfDriveCollection),
      where('agent_name', 'array-contains', prevAgent.fullname),
    )

    const chauffeurDriveCollection = chauffeurDriveCarCollectionName.value
    const queryChauffeurDrive = query(
      collection(db, chauffeurDriveCollection),
      where('agent_name', 'array-contains', prevAgent.fullname),
    )

    const batch = writeBatch(db)

    // 1. Update agent
    batch.update(agentDocRef, newAgent)

    // 2. Update Self Drive
    const selfDriveItems = await getDocs(querySelfDrive)
    selfDriveItems.forEach(document => {
      const docRef = doc(db, selfDriveCollection, document.id)

      const agent_active = document.data().agent_active
      const agent_name = document.data().agent_name
      const agent_show = document.data().agent_show

      const index = indexOf(agent_name, prevAgent.fullname)
      agent_active.splice(index, 1, active)
      agent_name.splice(index, 1, fullname)
      agent_show.splice(index, 1, fullname)

      batch.update(docRef, { agent_active, agent_name, agent_show })
    })

    // 3. Update Chauffeur Drive
    const chauffeurDriveItems = await getDocs(queryChauffeurDrive)
    chauffeurDriveItems.forEach(document => {
      const docRef = doc(db, chauffeurDriveCollection, document.id)

      const agent_active = document.data().agent_active
      const agent_name = document.data().agent_name
      const agent_show = document.data().agent_show

      const index = indexOf(agent_name, prevAgent.fullname)
      agent_active.splice(index, 1, active)
      agent_name.splice(index, 1, fullname)
      agent_show.splice(index, 1, fullname)

      batch.update(docRef, { agent_active, agent_name, agent_show })
    })

    await batch.commit()
  }

  const filterAgents = () => {
    let filteredCarAgents = agents.value
    if (searchQuery.value && searchQuery.value.length) {
      filteredCarAgents = filter(filteredCarAgents, ({ fullname, email, phone, location_id }) => {
        return stringContainsAny(fullname + email + phone + location_id, searchQuery.value)
      })
    }

    if (!isNil(statusFilter.value)) {
      filteredCarAgents = filter(filteredCarAgents, ({ active }) => {
        return statusFilter.value == active
      })
    }

    if (!isNil(locationFilter.value)) {
      filteredCarAgents = filter(filteredCarAgents, ({ location_id }) => {
        return locationFilter.value == location_id
      })
    }

    if (!isNil(sortBy.value)) {
      filteredCarAgents = sortOrder(filteredCarAgents, [sortBy.value.by], [sortBy.value.order])
    }

    filteredAgents.value = filteredCarAgents
  }

  watch([searchQuery, statusFilter, locationFilter, sortBy], () => {
    filterAgents()
  })

  watch([filteredAgents, itemsPerPage], () => {
    const chunkSize = itemsPerPage.value == 'All' ? filteredAgents.value.length : itemsPerPage.value
    paginatedAgents.value = chunk(filteredAgents.value, chunkSize)
    page.value = 1
  })

  const unsubscribeAgents = () => {
    unsubscribe()
  }

  fetchAgents()
  fetchLocations()

  return {
    page,
    itemsPerPage,
    agents,
    filteredAgents,
    paginatedAgents,
    searchQuery,
    statusFilter,
    locationFilter,
    sortBy,
    loading,
    agentData,
    blankAgentData,
    error,
    locationOptions,

    unsubscribeAgents,
    addAgent,
    editAgent,
  }
}
