var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"car-brand",attrs:{"id":"user-list"}},[_c('ChauffeurDriveAddNew',{attrs:{"id":_vm.carBrandId,"fleetOptions":_vm.fleetOptions,"locationOptions":_vm.allLocationOptions,"agentOptions":_vm.agentOptions},on:{"reset-id":function($event){_vm.carBrandId = null}},model:{value:(_vm.isAddNewCarBrandSidebarActive),callback:function ($$v) {_vm.isAddNewCarBrandSidebarActive=$$v},expression:"isAddNewCarBrandSidebarActive"}}),_c('v-card',[_c('v-card-title',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-auto"},[_vm._v("Chauffeur Drive")]),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"text-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":"","clearable":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-btn',{staticClass:"mb-4",attrs:{"icon":""},on:{"click":function($event){_vm.showFilters = !_vm.showFilters}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-1",attrs:{"size":"24"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiFilter)+" ")])]}}])},[_c('span',[_vm._v("Filters")])])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-1",attrs:{"size":"24"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiSort)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sort By")])])],1)]}}])},[_c('v-list',_vm._l((_vm.sortOptions),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.sortBy = item.value}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-btn',{staticClass:"mb-4",attrs:{"icon":""},on:{"click":function($event){return _vm.exportToExcel()}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-1",attrs:{"size":"24"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")])]}}])},[_c('span',[_vm._v("Download")])])],1)],1)]),(_vm.showFilters)?_c('v-card-text',{staticClass:"d-flex flex-wrap pb-0"},[_c('v-select',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Select Status","items":_vm.statusOptions,"item-text":"title","item-value":"value","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}}),_c('v-select',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Select Type","items":_vm.typeOptions,"item-text":"title","item-value":"value","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.typeFilter),callback:function ($$v) {_vm.typeFilter=$$v},expression:"typeFilter"}}),_c('v-select',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Select Location","items":_vm.locationOptions,"item-text":"title","item-value":"value","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.locationFilter),callback:function ($$v) {_vm.locationFilter=$$v},expression:"locationFilter"}}),_c('v-select',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Select Brand","items":_vm.brandOptions,"item-text":"title","item-value":"value","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.brandFilter),callback:function ($$v) {_vm.brandFilter=$$v},expression:"brandFilter"}})],1):_vm._e(),_c('p',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.filteredChauffeurDrive.length || 'No')+" records found")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4","xl":"3"}},[_c('AddNewCard',{attrs:{"title":"Add Car","minHeight":"175"},on:{"click":_vm.addChauffeurDrive}})],1),_vm._l((_vm.paginatedChauffeurDrive[_vm.page - 1]),function(ref,c){
          var id = ref.id;
          var carbrand = ref.carbrand;
          var markimgcarmob = ref.markimgcarmob;
          var active = ref.active;
          var markltype = ref.markltype;
          var updatedon = ref.updatedon;
          var carclass = ref.carclass;
          var location = ref.location;
          var active1 = ref.active1;
          var active2 = ref.active2;
          var active3 = ref.active3;
          var price1 = ref.price1;
          var price2 = ref.price2;
          var price3 = ref.price3;
          var package_hours1 = ref.package_hours1;
          var package_hrs2 = ref.package_hrs2;
          var package_kms1 = ref.package_kms1;
          var package_kms2 = ref.package_kms2;
          var toll = ref.toll;
          var parking_fee = ref.parking_fee;
          var soldout = ref.soldout;
          var clubmember = ref.clubmember;
          var popular = ref.popular;
return _c('v-col',{key:c,attrs:{"cols":"12","lg":"4","xl":"3"}},[_c('ChauffeurDriveCard',{attrs:{"id":id,"brandName":carbrand,"image":markimgcarmob,"isActive":active,"type":markltype,"updatedOn":_vm.formatDate(updatedon.toDate(), 'DD-MMM-YYYY hh:mm A'),"fleetClass":carclass,"location":location,"isFullDayActive":active1,"isOutstationActive":active2,"isAirportActive":active3,"fullDay":{ active: active1, price: price1, hours: package_hours1, kms: package_kms1 },"outstation":{ active: active2, price: price2, hours: package_hrs2, kms: package_kms2 },"airport":{ active: active3, price: price3, toll: toll, parkingFee: parking_fee },"soldout":soldout,"clubmember":clubmember,"popular":popular},on:{"edit-selfdrive":_vm.editChauffeurDrive}})],1)})],2),(_vm.filteredChauffeurDrive.length)?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mt-2 mr-auto ml-5"},[_c('span',[_vm._v("Items per page: "+_vm._s(_vm.itemsPerPage)+" ")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dark":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiMenuDown)+" ")])]}}],null,false,4256175007)},[_c('v-list',_vm._l((_vm.itemsPerPageOptions),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.itemsPerPage = item.title}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.countText))]),_c('v-pagination',{attrs:{"length":_vm.paginatedChauffeurDrive.length},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_vm._v(" ̉ ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }