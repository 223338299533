import ImageKit from 'imagekit-javascript'
import {imagekitConfig } from '../firebase/config'

const imagekit = new ImageKit(imagekitConfig)

export default function useImageKit() {
  const uploadFile = (file, name, tags = [], folder = '/') => {
    return new Promise((resolve, reject) => {
      imagekit.upload(
        {
          file: file,
          fileName: name,
          tags,
          folder,
        },
        (err, result) => {
          if (err) {
            console.log(err,"&&&&&&&&&&&&&&&&&&&&&&&&777")
            reject(err)
          } else {
            resolve(result.url)
          }
        },
      )
    })
  }

  return {
    uploadFile,
  }
}
