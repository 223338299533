<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="val => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary"> {{ id ? 'Update' : 'Add' }} Chauffeur Drive</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('update:is-add-new-user-sidebar-active', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <v-select
            v-model="chauffeurDriveData.markltype"
            :rules="[validators.required]"
            label="Car Type *"
            :items="typeOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
            @change="carTypeChanged"
          >
          </v-select>

          <v-select
            v-model="chauffeurDriveData.carbrand"
            :rules="[validators.required]"
            label="Brand *"
            :items="filterdBrandOptions"
            item-text="brand"
            item-value="brand"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
            @change="brandOptionChanged"
          >
          </v-select>

          <v-select
            v-model="chauffeurDriveData.carclass"
            :rules="[validators.required]"
            label="Class *"
            :items="filterdClassOptions"
            item-text="class"
            item-value="class"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
            @change="classChanged"
          >
          </v-select>

          <v-avatar tile size="80" v-if="chauffeurDriveData.markimgcarmob.length" class="mb-6">
            <v-img contain :src="chauffeurDriveData.markimgcarmob"></v-img>
          </v-avatar>

          <v-select
            v-model="chauffeurDriveData.location"
            :rules="[validators.required]"
            label="Location *"
            :items="locationOptions"
            item-text="description"
            item-value="description"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
            @change="locationChanged"
          >
          </v-select>

          <v-select
            v-model="chauffeurDriveData.agent_name"
            :rules="[validators.required]"
            label="Agents *"
            :items="filteredAgentOptions"
            item-text="fullname"
            item-value="fullname"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
            multiple
            @change="agentsChanged"
          >
          </v-select>

          <v-text-field
            v-model="chauffeurDriveData.color"
            outlined
            dense
            label="Color"
            placeholder="Color"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="chauffeurDriveData.launch_year"
            outlined
            dense
            label="Launch Year"
            placeholder="Launch Year"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <h3>Packages</h3>

          <v-checkbox v-model="chauffeurDriveData.active1" hide-details label="Standard"></v-checkbox>
          <template v-if="chauffeurDriveData.active1">
            <v-text-field
              type="number"
              v-model.number="chauffeurDriveData.price1"
              outlined
              dense
              :rules="[validators.requiredLength]"
              label="Price *"
              placeholder="Price"
              hide-details="auto"
              class="my-6"
            ></v-text-field>

            <v-text-field
              v-model="chauffeurDriveData.package_hours1"
              outlined
              dense
              :rules="[validators.required]"
              label="Package Hrs *"
              placeholder="Package Hrs"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              type="number"
              v-model.number="chauffeurDriveData.package_kms1"
              outlined
              dense
              :rules="[validators.requiredLength]"
              label="Kms Limit *"
              placeholder="Kms Limit"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>
          </template>
          <v-checkbox v-model="chauffeurDriveData.active2" hide-details label="Outstation"></v-checkbox>
          <template v-if="chauffeurDriveData.active2">
            <v-text-field
              type="number"
              v-model.number="chauffeurDriveData.price2"
              outlined
              dense
              :rules="[validators.requiredLength]"
              label="Price *"
              placeholder="Price"
              hide-details="auto"
              class="my-6"
            ></v-text-field>

            <v-text-field
              v-model="chauffeurDriveData.package_hrs2"
              outlined
              dense
              :rules="[validators.required]"
              label="Package Hrs *"
              placeholder="Package Hrs"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              type="number"
              v-model.number="chauffeurDriveData.package_kms2"
              outlined
              dense
              :rules="[validators.requiredLength]"
              label="Kms Limit *"
              placeholder="Kms Limit"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>
          </template>
          <v-checkbox v-model="chauffeurDriveData.active3" hide-details label="Airport"></v-checkbox>
          <template v-if="chauffeurDriveData.active3">
            <v-text-field
              type="number"
              v-model.number="chauffeurDriveData.price3"
              outlined
              dense
              :rules="[validators.requiredLength]"
              label="Price *"
              placeholder="Price"
              hide-details="auto"
              class="my-6"
            ></v-text-field>
            <v-text-field
              type="number"
              v-model.number="chauffeurDriveData.toll"
              outlined
              dense
              :rules="[validators.requiredLength]"
              label="Toll *"
              placeholder="Toll"
              hide-details="auto"
              class="my-6"
            ></v-text-field>
            <v-text-field
              type="number"
              v-model.number="chauffeurDriveData.parking_fee"
              outlined
              dense
              :rules="[validators.requiredLength]"
              label="Parking Fee *"
              placeholder="Parking Fee"
              hide-details="auto"
              class="my-6"
            ></v-text-field>
          </template>
          <v-checkbox v-model="chauffeurDriveData.offer" hide-details label="Offer"></v-checkbox>

          <v-switch
            v-model="chauffeurDriveData.active"
            inset
            :color="chauffeurDriveData.active ? 'success' : 'error'"
            :label="`${chauffeurDriveData.active ? 'Active' : 'Inactive'}`"
          ></v-switch>

          <v-alert dense text color="error" v-if="error">
            {{ error }}
          </v-alert>

          <v-btn color="primary" class="me-3" :loading="loading" type="submit">
            {{ id ? 'Update' : 'Add' }}
          </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetselfDriveData"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose, mdiCircle } from '@mdi/js'
import { find, filter, each } from 'lodash'
import { ref, watch, computed } from '@vue/composition-api'
import { required, emailValidator, requiredLength } from '@core/utils/validation'
import useChauffeurDrive from './useChauffeurDrive'

export default {
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    id: {
      type: String | null,
      required: true,
    },
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    fleetOptions: {
      type: Array,
      required: true,
    },
    locationOptions: {
      type: Array,
      required: true,
    },
    agentOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const typeOptions = [
      { title: 'Luxury', value: 'Luxury' },
      { title: 'Sports Car', value: 'Sports Car' },
      { title: 'Executive', value: 'Executive' },
      { title: 'Super Car', value: 'Super Car' },
      { title: 'Standard', value: 'Standard' },
      { title: 'Premium', value: 'Premium' },
    ]
    const fileRules = [v => !!v || 'File is required', v => (v && v.size > 0) || 'File is required']
    const valid = ref(false)
    const form = ref(null)
    const imageUrl = ref('')

    const packageHoursOptions = ['24', '9am - 9pm', '12am - 12am']

    const {
      addChauffeurDrive,
      chauffeurDrive,
      chauffeurDriveData,
      blankChauffeurDriveData,
      error,
      loading,
      editChauffeurDrive,
    } = useChauffeurDrive()

    const filterdBrandOptions = computed(() => {
      if (chauffeurDriveData.value.markltype && chauffeurDriveData.value.markltype.length) {
        return filter(props.fleetOptions, ({ markltype }) => markltype == chauffeurDriveData.value.markltype)
      }
      return []
    })

    const filterdClassOptions = computed(() => {
      if (chauffeurDriveData.value.carbrand && chauffeurDriveData.value.carbrand.length) {
        return filter(filterdBrandOptions.value, ({ brand }) => brand == chauffeurDriveData.value.carbrand)
      }
      return []
    })

    const filteredAgentOptions = computed(() => {
      if (chauffeurDriveData.value.location && chauffeurDriveData.value.location.length) {
        return filter(props.agentOptions, ({ location_id }) => location_id == chauffeurDriveData.value.location)
      }
      return []
    })

    const validate = () => {
      form.value.validate()
    }

    const resetForm = async () => {
      await form.value.reset()
      chauffeurDriveData.value = JSON.parse(JSON.stringify(blankChauffeurDriveData))
    }

    const resetselfDriveData = () => {
      resetForm()
      error.value = null
      emit('reset-id')
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const onSubmit = async () => {
      if (valid.value) {
        if (props.id) {
          await editChauffeurDrive(props.id)
        } else {
          await addChauffeurDrive()
        }
        if (!error.value) {
          resetselfDriveData()
        }
      } else {
        validate()
      }
    }

    const onFileChange = file => {
      if (!file) {
        return
      }
      createImage(file)
    }

    const createImage = file => {
      const reader = new FileReader()

      reader.onload = e => {
        imageUrl.value = e.target.result
      }
      reader.readAsDataURL(file)
    }

    const classChanged = item => {
      const selectedClass = find(filterdClassOptions.value, ['class', item])
      chauffeurDriveData.value.markimgcarmob = selectedClass.markimgcarmob
      chauffeurDriveData.value.brandactive = selectedClass.brandactive
      chauffeurDriveData.value.brandimg = selectedClass.brandimg
      chauffeurDriveData.value.carfleet_id = selectedClass.id
      chauffeurDriveData.value.carfleetactive = selectedClass.active
       chauffeurDriveData.value.avatarImages = selectedClass.avatarImages
    }

    const brandOptionChanged = () => {
      chauffeurDriveData.value.carclass = ''
      chauffeurDriveData.value.markimgcarmob = ''
      chauffeurDriveData.value.brandactive = null
      chauffeurDriveData.value.brandimg = ''
      chauffeurDriveData.value.carfleet_id = ''
      chauffeurDriveData.value.carfleetactive = null
    }

    const carTypeChanged = () => {
      chauffeurDriveData.value.carbrand = ''
      brandOptionChanged()
    }

    const agentsChanged = items => {
      chauffeurDriveData.value.agent_show = items
      chauffeurDriveData.value.agent_active = []
      each(items, item => {
        const agentData = find(filteredAgentOptions.value, ['fullname', item])
        chauffeurDriveData.value.agent_active.push(agentData ? agentData.active : false)
      })
    }

    const locationChanged = item => {
      const locationData = find(props.locationOptions, ['description', item])
      chauffeurDriveData.value.locactive = locationData.active
    }

    watch(
      () => props.id,
      () => {
        if (props.id) {
          const chauffD = find(chauffeurDrive.value, ['id', props.id])
          chauffeurDriveData.value.active = chauffD.active
          chauffeurDriveData.value.active1 = chauffD.active1
          chauffeurDriveData.value.active2 = chauffD.active2
          chauffeurDriveData.value.active3 = chauffD.active3
          chauffeurDriveData.value.agent_active = chauffD.agent_active
          chauffeurDriveData.value.agent_name = chauffD.agent_name
          chauffeurDriveData.value.agent_show = chauffD.agent_show
          chauffeurDriveData.value.brandactive = chauffD.brandactive
          chauffeurDriveData.value.brandclass = chauffD.brandclass
          chauffeurDriveData.value.brandimg = chauffD.brandimg
          chauffeurDriveData.value.carbrand = chauffD.carbrand
          chauffeurDriveData.value.carclass = chauffD.carclass
          chauffeurDriveData.value.carfleet_id = chauffD.carfleet_id
          chauffeurDriveData.value.carfleetactive = chauffD.carfleetactive
          chauffeurDriveData.value.color = chauffD.color
          chauffeurDriveData.value.launch_year = chauffD.launch_year
          chauffeurDriveData.value.locactive = chauffD.locactive
          chauffeurDriveData.value.location = chauffD.location
          chauffeurDriveData.value.markimgcarmob = chauffD.markimgcarmob
          chauffeurDriveData.value.markltype = chauffD.markltype
          chauffeurDriveData.value.package_hours1 = chauffD.package_hours1
          chauffeurDriveData.value.package_hrs2 = chauffD.package_hrs2
          chauffeurDriveData.value.package_kms1 = chauffD.package_kms1
          chauffeurDriveData.value.package_kms2 = chauffD.package_kms2
          chauffeurDriveData.value.parking_fee = chauffD.parking_fee
          chauffeurDriveData.value.price1 = chauffD.price1
          chauffeurDriveData.value.price2 = chauffD.price2
          chauffeurDriveData.value.price3 = chauffD.price3
          chauffeurDriveData.value.toll = chauffD.toll
          chauffeurDriveData.value.offer = chauffD.offer
    
          imageUrl.value = chauffD.markimgcarmob
        }
      },
      { immediate: true },
    )

    watch(
      () => props.isAddNewUserSidebarActive,
      () => {
        if (!props.isAddNewUserSidebarActive) {
          resetselfDriveData()
        }
      },
    )

    return {
      typeOptions,
      resetselfDriveData,
      form,
      onSubmit,
      chauffeurDriveData,
      valid,
      validate,
      error,
      fileRules,
      imageUrl,
      onFileChange,
      loading,
      filterdBrandOptions,
      filterdClassOptions,
      classChanged,
      brandOptionChanged,
      carTypeChanged,
      filteredAgentOptions,
      packageHoursOptions,
      agentsChanged,
      locationChanged,
      // validation
      validators: { required, emailValidator, requiredLength },
      icons: {
        mdiClose,
        mdiCircle,
      },
    }
  },
}
</script>
