<template>
  <v-hover v-slot="{ hover }">
    <v-card class="mx-auto car-fleet-card" max-width="344" outlined>
      <v-list-item two-line class="mt-4">
        <v-list-item-avatar class="" tile width="130" height="100">
          <v-img contain :src="image"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="mb-1"> {{ brandName }} {{ fleetClass }} </v-list-item-title>
          <v-list-item-subtitle class="last-modified d-flex mt-1">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" x-small>{{ icons.mdiCalendarBlankOutline }}</v-icon>
              </template>
              <span>Last Modified</span>
            </v-tooltip>
            <div class="ml-1">{{ updatedOn }}</div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions class="mt-n5">
        <span class="primary--text"> Packages </span>

        <v-spacer></v-spacer>
        <v-chip x-small v-if="fullDay.active">Standard</v-chip>
        <v-chip x-small v-if="outstation.active">Outstation</v-chip>
        <v-chip x-small v-if="airport.active">Airport</v-chip>
        <v-btn icon @click="showDetail = !showDetail">
          <v-icon>{{ showDetail ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="showDetail">
          <v-divider></v-divider>

          <v-card-text>
            <template v-if="fullDay.active">
              <h3>Standard</h3>
              <v-row>
                <v-col>
                  <span class="white--text">Price</span>
                  <p>{{ $store.getters['appConfig/currencySymbol'] }} {{ formatNumber(fullDay.price) }}</p>
                </v-col>
                <v-col>
                  <span class="white--text">Hours</span>
                  <p>{{ fullDay.hours }}</p> </v-col
                ><v-col>
                  <span class="white--text">Kms</span>
                  <p>{{ fullDay.kms }}</p>
                </v-col>
              </v-row>
            </template>

            <template v-if="outstation.active">
              <h3>Outstation</h3>
              <v-row>
                <v-col>
                  <span class="white--text">Price</span>
                  <p>{{ $store.getters['appConfig/currencySymbol'] }} {{ formatNumber(outstation.price) }}</p>
                </v-col>
                <v-col>
                  <span class="white--text">Hours</span>
                  <p>{{ outstation.hours }}</p> </v-col
                ><v-col>
                  <span class="white--text">Kms</span>
                  <p>{{ outstation.kms }}</p>
                </v-col>
              </v-row>
            </template>

            <template v-if="airport.active">
              <h3>Airport</h3>
              <v-row>
                <v-col>
                  <span class="white--text">Price</span>
                  <p>{{ $store.getters['appConfig/currencySymbol'] }} {{ formatNumber(airport.price) }}</p>
                </v-col>
                <v-col>
                  <span class="white--text">Toll</span>
                  <p>{{ $store.getters['appConfig/currencySymbol'] }} {{ airport.toll }}</p> </v-col
                ><v-col>
                  <span class="white--text">Parking Fee</span>
                  <p>{{ $store.getters['appConfig/currencySymbol'] }} {{ formatNumber(airport.parkingFee) }}</p>
                </v-col>
              </v-row>
            </template>
          </v-card-text>
        </div>
      </v-expand-transition>
      <div>
        <div class="status-icon d-flex">
          <v-icon class="mt-1" x-small :color="isActive ? 'success' : 'error'">{{ icons.mdiCircle }}</v-icon>
          <span class="ml-2 brand-type">{{ type }}</span>
          <v-chip v-if="soldout" class="ml-1 v-chip-light-bg error--text" color="error" small>SOLD OUT</v-chip>
          <v-img class="ml-1" v-else-if="clubmember" src="https://ik.imagekit.io/egrcxziyv/club.png" width="14"></v-img>
          <v-img class="ml-1" v-else-if="popular" src="https://res.cloudinary.com/doot8az4x/image/upload/v1630049069/Trendicon_uaz7ts.png" width="22"></v-img>
        </div>
        <v-chip class="ma-2 v-chip-light-bg primary--text location-icon" color="primary" small>
          {{ location }}
        </v-chip>
        <v-icon class="ml-4 edit-icon" small @click="$emit('edit-selfdrive', id)">{{ icons.mdiPencil }}</v-icon>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
import { mdiCircle, mdiPencil, mdiCalendarBlankOutline, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { formatNumber } from '@core/utils/filter'

export default {
  components: {},
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String | undefined,
      required: true,
    },
    image: {
      type: String | undefined,
      required: true,
    },
    brandName: {
      type: String,
      required: true,
    },
    location: {
      type: String,
      required: true,
    },
    updatedOn: {
      type: String,
      required: true,
    },
    fleetClass: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    isFullDayActive: {
      type: Boolean,
      required: true,
    },
    isOutstationActive: {
      type: Boolean,
      required: true,
    },
    isAirportActive: {
      type: Boolean,
      required: true,
    },
    fullDay: {
      type: Object,
      required: true,
    },
    outstation: {
      type: Object,
      required: true,
    },
    airport: {
      type: Object,
      required: true,
    },
    soldout: {
      type: Boolean,
      required: false,
    },
    clubmember: {
      type: Boolean,
      required: false,
    },
    popular: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const showDetail = ref(false)
    const extraDetail = ref(
      `${props.seat} people &#8226; ${props.luggage} bags &#8226; ${props.gear} &#8226; ${props.fuel}`,
    )
    return {
      showDetail,
      extraDetail,
      formatNumber,
      icons: {
        mdiCircle,
        mdiPencil,
        mdiCalendarBlankOutline,
        mdiChevronUp,
        mdiChevronDown,
      },
    }
  },
}
</script>

<style scoped lang="scss">
.car-fleet-card {
  .status-icon {
    position: absolute;
    cursor: pointer;
    left: 10px;
    top: 5px;
  }

  .location-icon {
    position: absolute;
    cursor: pointer;
    right: 25px;
    top: -2px;
  }

  .edit-icon {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 8px;
    color: rgb(88, 88, 88);
  }

  .brand-type {
    font-size: 00.8rem;
  }

  .last-modified {
    font-size: 0.7rem;
  }

  .card-actions {
    position: absolute;
    cursor: pointer;
    // left: 10px;
    bottom: 0px;
  }

  .last-modified {
    font-size: 0.7rem;
  }
}
</style>
