import useAppConfig from '@/@core/@app-config/useAppConfig'
import useCommon from '@/composables/useCommon'
import useFirestoreCollectionNames from '@/composables/useFirestoreCollectionNames'
import { ref, watch } from '@vue/composition-api'
import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
  writeBatch,
} from 'firebase/firestore'
import { chunk, filter, isNil, orderBy as sortOrder } from 'lodash'
import useImageKit from '../../../composables/useImageKit'
import { db } from '/src/firebase/config'

export default function useCarFleetList() {
  const { stringContainsAny } = useCommon()
  const { country } = useAppConfig()
  const { carFleetCollectionName, selfDriveCarCollectionName, chauffeurDriveCarCollectionName } =
    useFirestoreCollectionNames()

  const carFleetCollection = collection(db, carFleetCollectionName.value)
  let blankCarFleetData = {
    brand: '',
    brandactive: null,
    brandimg: '',
    class: '',
    features: [],
    featuresicon: [],
    fuel: '',
    gear: '',
    lugguage: null,
    seat: null,
    active: true,
    avatarImages:[],
    faq:[]

  }

  if (country.value == 'UK') {
    blankCarFleetData = {
      ...blankCarFleetData,
      ltype: '',
      imgcarmob: '',
      imgslider: [],
    }
  } else {
    blankCarFleetData = {
      ...blankCarFleetData,
      markltype: '',
      markimgcarmob: '',
      markimgslider: [],
    }
  }

  const carFleetData = ref(JSON.parse(JSON.stringify(blankCarFleetData)))

  const carFleets = ref([])
  const filteredCarFleet = ref([])
  const paginatedCarFleet = ref([])
  const error = ref(null)
  const page = ref(1)
  const itemsPerPage = ref(15)

  const searchQuery = ref('')
  const statusFilter = ref(true)
  const typeFilter = ref(null)
  const sortBy = ref(null)
  const loading = ref(false)
  let unsubscribe = null
  const options = ref({
    sortBy: ['updatedon'],
    sortDesc: [true],
  })

  const typeKey = country.value == 'UK' ? 'ltype' : 'markltype'
  const imgcarmobKey = country.value == 'UK' ? 'imgcarmob' : 'markimgcarmob'
  const imgsliderKey = country.value == 'UK' ? 'imgslider' : 'markimgslider'

  const queryOptions = () => {
    const qOptions = []

    qOptions.push(where('brandactive', '==', true))

    const orderByKey = options.value.sortBy[0] ? options.value.sortBy[0] : 'updatedon'
    const order = options.value.sortDesc[0] ? 'desc' : 'asc'
    qOptions.push(orderBy(orderByKey, order))

    return qOptions
  }

  // fetch data
  const fetchCarFleet = async () => {
    try {
      if (unsubscribe) {
        unsubscribe()
      }
      loading.value = true
      carFleets.value = []
      filteredCarFleet.value = []

      const q = query(carFleetCollection, ...queryOptions())
      unsubscribe = onSnapshot(q, querySnapshot => {
        carFleets.value = []
        querySnapshot.forEach(doc => {
          carFleets.value.push({
            id: doc.id,
            ...doc.data(),
          })
        })
        filteredCarFleet.value = carFleets.value
        filterCarFleet()
      })
    } catch (e) {
      console.log(e)
    } finally {
      loading.value = false
    }
  }

  const checkIfCarFleetExist = async () => {
    const q = query(
      carFleetCollection,
      where('brand', '==', carFleetData.value.brand),
      where(typeKey, '==', carFleetData.value[typeKey]),
      where('class', '==', carFleetData.value.class),
    )
    const querySnapshot = await getDocs(q)
    return !!querySnapshot.size
  }

  const addCarFleet = async () => {
    try {
      console.log(carFleetData.value['avatarImages'],"*************************")
      loading.value = true
      error.value = null
      if (!carFleetData.value[imgcarmobKey]) {
        throw new Error('Please add app landing image')
      }
      if (!carFleetData.value[imgsliderKey].length) {
        throw new Error('Please add slider images')
      }
      if (await checkIfCarFleetExist()) {
        throw new Error('Car fleet already exist!')
      }
      const { uploadFile } = useImageKit()
      const imageFile = carFleetData.value[imgcarmobKey]
      const imageName = carFleetData.value[imgcarmobKey].name
      const tags = [carFleetData.value.brand, carFleetData.value[typeKey], carFleetData.value.class]
      const fileUrl = await uploadFile(imageFile, imageName, tags, '/mark5/')
      const sliderImages = []
      for (let i = 0; i < carFleetData.value[imgsliderKey].length; i++) {
        const item = carFleetData.value[imgsliderKey][i]
        const itemUrl = await uploadFile(item, item.name, tags, '/hypeluxury/')
        sliderImages.push(itemUrl)
      }
 
      const avatarImagesArr=[]   
     
      if(carFleetData.value['avatarImages'] !== undefined){
        for (let i = 0; i < carFleetData.value['avatarImages'].length; i++) {
          const item = carFleetData.value['avatarImages'][i]
          const itemUrl = await uploadFile(item, item.name, tags, '/avatar/cars/')
          avatarImagesArr.push(itemUrl)
        }  
      }   
      
      let carFleet = {
        ...carFleetData.value,
        createdon: serverTimestamp(),
        updatedon: serverTimestamp(),
      }
      carFleet[imgcarmobKey] = fileUrl
      carFleet[imgsliderKey] = sliderImages.join('|')
      carFleet['avatarImages']  = avatarImagesArr;
      await addDoc(carFleetCollection, carFleet)
    } catch (e) {
      error.value = e.message
      console.log(e)
    } finally {
      loading.value = false
    }
  }

  const editCarFleet = async id => {
    try {
      loading.value = true
      error.value = null
      const prevCarFleet = filter(carFleets.value, carFleet => carFleet.id == id)
      let carFleet = {
        ...carFleetData.value,
        updatedon: serverTimestamp(),
      }
      if (
        prevCarFleet.length &&
        (prevCarFleet[0].brand != carFleet.brand ||
          prevCarFleet[0][typeKey] != carFleet[typeKey] ||
          prevCarFleet[0].class != carFleet.class)
      ) {
        if (await checkIfCarFleetExist()) {
          throw new Error('Car fleet already exist!')
        }
      }

      const { uploadFile } = useImageKit()
      const tags = [carFleetData.value.brand, carFleetData.value[typeKey], carFleetData.value.class]

      if (!carFleetData.value[imgcarmobKey]) {
        throw new Error('Please add brand logo')
      } else if (carFleetData.value[imgcarmobKey] instanceof File) {
        const imageFile = carFleetData.value[imgcarmobKey]
        const imageName = carFleetData.value[imgcarmobKey].name
        const fileUrl = await uploadFile(imageFile, imageName, tags, '/cars/')
        carFleet = {
          ...carFleet,
        }
        carFleet[imgcarmobKey] = fileUrl
      }

      if (!carFleetData.value[imgsliderKey].length) {
        throw new Error('Please add slider images')
      } else {
        const sliderImages = []
        for (let i = 0; i < carFleetData.value[imgsliderKey].length; i++) {
          const item = carFleetData.value[imgsliderKey][i]
          let itemUrl = ''
          if (item instanceof File) {
            itemUrl = await uploadFile(item, item.name, tags, '/hypeluxury/')
          } else {
            itemUrl = item
          }
          sliderImages.push(itemUrl)
        }

        const avatarImagesArr = []

        if(carFleetData.value['avatarImages'] !== undefined){
          for (let i = 0; i < carFleetData.value['avatarImages'].length; i++) {
            const item = carFleetData.value['avatarImages'][i]
            let itemUrl = ''
            if (item instanceof File) {
              itemUrl = await uploadFile(item, item.name, tags, '/avatar/cars/')
            } else {
              itemUrl = item
            }
            avatarImagesArr.push(itemUrl)
          }
        }
        

        carFleet = {
          ...carFleet,
        }
        carFleet[imgsliderKey] = sliderImages.join('|')
        carFleet['avatarImages']  = avatarImagesArr;
      }
      await batchUpdate(id, prevCarFleet[0], carFleet)
      // const docReference = doc(db, carFleetCollectionName.value, id)
      // await updateDoc(docReference, carFleet)
    } catch (e) {
      error.value = e.message
      console.log(e)
    } finally {
      loading.value = false
    }
  }

  const batchUpdate = async (fleetId, prevCarFleet, newCarFleet) => {
    const { brand, class: fleetClass, active, avatarImages } = newCarFleet
    const carFleetDocRef = doc(db, carFleetCollectionName.value, fleetId)

    const selfDriveCollection = selfDriveCarCollectionName.value
    const querySelfDrive = query(collection(db, selfDriveCollection), where('carfleet_id', '==', fleetId))

    const chauffeurDriveCollection = chauffeurDriveCarCollectionName.value
    const queryChauffeurDrive = query(collection(db, chauffeurDriveCollection), where('carfleet_id', '==', fleetId))
    const batch = writeBatch(db)

    // 1. Update brand
    batch.update(carFleetDocRef, newCarFleet)

    // 2. Update Self Drive
    let updateObjSelfDrive = {
      carbrand: brand,
      carclass: fleetClass,
      carfleetactive: active,
      avatarImages: avatarImages
    }
    updateObjSelfDrive[imgcarmobKey] = newCarFleet[imgcarmobKey]
    updateObjSelfDrive[typeKey] = newCarFleet[typeKey]
    const selfDriveItems = await getDocs(querySelfDrive)
    selfDriveItems.forEach(document => {
      const docRef = doc(db, selfDriveCollection, document.id)
      batch.update(docRef, updateObjSelfDrive)
    })

    // 3. Update Chauffeur Drive
    const updateObjChauffeurDrive = {
      carbrand: brand,
      carclass: fleetClass,
      carfleetactive: active,
      avatarImages: avatarImages
    }
    updateObjChauffeurDrive[imgcarmobKey] = newCarFleet[imgcarmobKey]
    updateObjChauffeurDrive[typeKey] = newCarFleet[typeKey]
    const chauffeurDriveItems = await getDocs(queryChauffeurDrive)
    chauffeurDriveItems.forEach(document => {
      const docRef = doc(db, chauffeurDriveCollection, document.id)
      batch.update(docRef, updateObjChauffeurDrive)
    })

    await batch.commit()
  }

  const filterCarFleet = () => {
    let filteredBrands = carFleets.value
    if (searchQuery.value && searchQuery.value.length) {
      filteredBrands = filter(filteredBrands, item => {
        return stringContainsAny(item.brand + item[typeKey] + item.class, searchQuery.value)
      })
    }

    if (!isNil(statusFilter.value)) {
      filteredBrands = filter(filteredBrands, ({ active }) => {
        return statusFilter.value == active
      })
    }

    if (typeFilter.value && typeFilter.value.length) {
      filteredBrands = filter(filteredBrands, item => {
        return typeFilter.value == item[typeKey]
      })
    }

    if (!isNil(sortBy.value)) {
      filteredBrands = sortOrder(filteredBrands, [sortBy.value.by], [sortBy.value.order])
    }

    filteredCarFleet.value = filteredBrands
  }

  watch([searchQuery, statusFilter, typeFilter, sortBy], () => {
    filterCarFleet()
  })

  watch([filteredCarFleet, itemsPerPage], () => {
    const chunkSize = itemsPerPage.value == 'All' ? filteredCarFleet.value.length : itemsPerPage.value
    paginatedCarFleet.value = chunk(filteredCarFleet.value, chunkSize)
    page.value = 1
  })

  const unsubscribeCarFleet = () => {
    unsubscribe()
  }

  fetchCarFleet()

  return {
    carFleets,
    page,
    itemsPerPage,
    filteredCarFleet,
    paginatedCarFleet,
    searchQuery,
    statusFilter,
    typeFilter,
    sortBy,
    loading,
    carFleetData,
    blankCarFleetData,
    error,

    unsubscribeCarFleet,
    addCarFleet,
    editCarFleet,
  }
}
